/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import {
  AuditorType,
  CriteriaInterface,
  EvidenceType,
  IndicatorInterface,
  InputType,
  PrincipleInterface,
  StandardDefinitionInterface,
  StandardMethod,
  StandardVersion,
} from '@eva/certification/api';

import StandardDefinitionData_0400 from './versions/0.4.00.json';
import StandardDefinitionData_1_0 from './versions/1.0.04.json';
import StandardDefinitionData_1_1 from './versions/1.1.00.json';

export class StandardDefinitionService {
  static latestVersion = '1.1' as StandardVersion;

  static findByVersion(version: string): StandardDefinitionInterface {
    switch (version) {
      case '0.4':
      case '0.4.03':
      case '0.4.04': {
        return StandardDefinitionData_0400 as StandardDefinitionInterface;
      }
      case '1.0': {
        return StandardDefinitionData_1_0 as StandardDefinitionInterface;
      }
      case '1.1': {
        return StandardDefinitionData_1_1 as StandardDefinitionInterface;
      }
      default:
        throw new Error(`Version ${version} not supported!`);
    }
  }

  static getLatest(): StandardDefinitionInterface {
    return StandardDefinitionService.findByVersion(
      StandardDefinitionService.latestVersion
    );
  }

  static getLatestVersion(): StandardVersion {
    return StandardDefinitionService.latestVersion;
  }

  /**
   * rmove patch version for Versioning string
   */
  static getMajorVersion(standardVersion: string): StandardVersion {
    const versionParts = standardVersion.split('.');
    return (versionParts[0] + '.' + versionParts[1]) as StandardVersion;
  }

  static getAll() {
    return [
      StandardDefinitionData_0400,
      StandardDefinitionData_1_0,
      StandardDefinitionData_1_1,
    ] as StandardDefinitionInterface[];
  }

  static getIndicator(id: string, version: string): IndicatorInterface | null {
    const standard = StandardDefinitionService.findByVersion(version);
    if (standard) {
      const indicator = StandardDefinitionService.getIndicators(standard).find(
        (i) => i.identifier === id
      );
      if (indicator !== undefined) {
        return indicator;
      }
    }
    return null;
  }

  static getIndicators(s: StandardDefinitionInterface): IndicatorInterface[] {
    const i = s.principles
      .map((p: PrincipleInterface) => [
        ...p.criterias.map((c: CriteriaInterface) => {
          const r = [...c.indicators];
          return r;
        }),
      ])
      .flatMap((i) => i)
      .flatMap((i) => i);
    return i;
  }

  /**
   * returns an object with identifiers as key
   * for fast access by identifier to indicators
   */
  static getIndicatorsByVersion(
    version: string
  ): { [key: string]: IndicatorInterface } | null {
    const standardVersion = StandardDefinitionService.findByVersion(version);
    if (standardVersion) {
      const indicators =
        StandardDefinitionService.getIndicators(standardVersion);
      const initial: { [key: string]: IndicatorInterface } = {};
      const indicatorObject = indicators.reduce(
        (prev, curr): { [key: string]: IndicatorInterface } => {
          prev[curr.identifier] = curr;
          return prev;
        },
        initial
      );
      return indicatorObject;
    } else {
      return null;
    }
  }

  static getIndicatorsByInputType(
    inputType: InputType,
    version: string
  ): IndicatorInterface[] | null {
    const standardVersion = StandardDefinitionService.findByVersion(version);
    if (standardVersion) {
      return StandardDefinitionService.getIndicators(standardVersion).filter(
        (i) => i.inputType === inputType
      );
    } else {
      return null;
    }
  }

  static getIndicatorsByEvidenceType(
    evidenceType: EvidenceType,
    version: string
  ): IndicatorInterface[] | null {
    const standardVersion = StandardDefinitionService.findByVersion(version);
    if (standardVersion) {
      const indicators =
        StandardDefinitionService.getIndicators(standardVersion);
      return indicators.filter((i) =>
        i.evidenceProvidedBy?.includes(evidenceType)
      );
    } else {
      return null;
    }
  }

  static getIndicatorsNeedingConfirmation(
    version: string
  ): IndicatorInterface[] | null {
    const standardVersion = StandardDefinitionService.findByVersion(version);
    if (standardVersion) {
      const indicators =
        StandardDefinitionService.getIndicators(standardVersion);
      return indicators.filter(
        (i) =>
          i.evidenceProvidedBy?.includes(EvidenceType.CONFIRM) ||
          i.evidenceProvidedBy?.includes(
            EvidenceType.DEFAULTS_MANDATORY_TEXT
          ) ||
          i.evidenceProvidedBy?.includes(EvidenceType.DEFAULTS_OPTIONAL_TEXT)
      );
    } else {
      return null;
    }
  }

  /**
   * these indicators have to be reviewed in any certification
   * even in non-sample certifications in group certifications
   */
  static getIndicatorsNeedingUniqueReview(
    version: string
  ): IndicatorInterface[] | null {
    const standardVersion = StandardDefinitionService.findByVersion(version);
    if (standardVersion) {
      const indicators =
        StandardDefinitionService.getIndicators(standardVersion);
      return indicators.filter((i) =>
        [version + '-1.2.1', version + '-3.2.1', version + '-4.1.1'].includes(
          i.identifier
        )
      );
    }
    return null;
  }

  static getIndicatorsByMethod(
    s: StandardDefinitionInterface,
    standardMethod?: StandardMethod
  ): IndicatorInterface[] {
    const method =
      standardMethod === StandardMethod.AR ? 'REFORESTATION' : standardMethod;
    const indications = StandardDefinitionService.getIndicators(s).filter(
      (i) => !method || i.scopes.length === 0 || i.scopes.includes(method)
    );
    return indications;
  }

  static getAuditorIndications(
    version: string,
    standardMethod: StandardMethod
  ) {
    const standard = StandardDefinitionService.findByVersion(version);
    const indicators = StandardDefinitionService.getIndicatorsByMethod(
      standard,
      standardMethod
    );
    return indicators.filter(
      (i) =>
        i.auditorType === AuditorType.AUDITOR && i.inputType !== InputType.NA
    );
  }
}
