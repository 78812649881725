<div *ngIf="title" class="editor-label py-3 px-3">
  <span i18n="{{titleI18n}}">{{title}}</span>
</div>
<p-editor
  i18n-pTooltip="{{ i18nTooltip }}"
  class="w-full"
  [formats]="formats"
  [style]="editorStyle"
  [ngModel]="value"
  (onTextChange)="onEditorContentChange($event)">
  <ng-template pTemplate="header">
    <div class="flex align-items-center justify-content-between w-full">
      <div class="flex align-items-center">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button
            type="button"
            class="ql-underline"
            aria-label="Underline"></button>
        </span>
        <span class="ql-formats">
          <button type="button" class="ql-list" value="ordered"></button>
          <button type="button" class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
          <button type="button" class="ql-link"></button>
        </span>
        <ng-content></ng-content>
      </div>
      <div class="flex align-items-center">
        <ng-container *ngIf="maximize">
          <eva-button
            class="ml-2"
            [type]="'icon-only'"
            [icon]="{ name: 'pi pi-window-maximize' }"
            (click)="$event.preventDefault(); openFullscreenEditor()"></eva-button>
        </ng-container>
      </div>
    </div>
  </ng-template>
</p-editor>
