/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Role } from '@eva/certification/api';
import { MainComponent } from './components/layout/main.component';
import { HelpComponent } from './components/static/help/help.component';
import { AuthGuard } from './guards/authGuard';
import { AfterLoginPreloadingStrategy } from './shared/after-login.preloading';
import { ROUTES } from './shared/routes';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: MainComponent,
          canActivateChild: [AuthGuard],
          children: [
            {
              path: ROUTES.DASHBOARD,
              loadComponent: () =>
                import('./components/dashboard/dashboard.component').then(
                  (m) => m.DashboardComponent
                ),
              data: {
                hideBreadcrumb: true,
                space: false,
              },
            },
            {
              path: 'project',
              loadChildren: () =>
                import('./components/project/project.module').then(
                  (m) => m.ProjectModule
                ),
            },
            {
              path: ROUTES.ASSIGN_AUDITORS,
              loadComponent: () =>
                import(
                  './components/auditor/assign/assign-auditors.component'
                ).then((m) => m.AssignAuditorsComponent),
              data: {
                breadcrumb: $localize`:@@assign-auditors:Auditor-Zuordnung`,
                roles: [Role.ADMIN, Role.AUDITOR],
              },
            },
            {
              path: ROUTES.SYSTEM_ADMINISTRATION,
              loadComponent: () =>
                import(
                  './components/system/administration/system-administration.component'
                ).then((m) => m.SystemAdministrationComponent),
              data: {
                breadcrumb: $localize`:@@system-administration:System-Einstellungen`,
                roles: [Role.ADMIN],
              },
            },
            {
              path: ROUTES.GROUP_CERTIFICATION,
              loadComponent: () =>
                import(
                  './components/group-certification/components/group-certification-container/group-certification-container.component'
                ).then((m) => m.GroupCertificationContainerComponent),
              data: {
                roles: [Role.CONSULTANT, Role.ADMIN, Role.AUDITOR],
              },
            },
            {
              path: 'certification',
              loadChildren: () =>
                import('./components/certification/certification.module').then(
                  (m) => m.CertificationModule
                ),
              data: {
                oldLayout: false,
              },
            },
            {
              path: ROUTES.USERS_LIST,
              loadComponent: () =>
                import('./components/user/list/list.users.component').then(
                  (m) => m.ListUsersComponent
                ),
              data: {
                roles: [
                  Role.PROJECT_MANAGER,
                  Role.CONSULTANT,
                  Role.ADMIN,
                  Role.AUDITOR,
                ],
                hideBreadcrumb: true,
              },
            },
            {
              path: ROUTES.USER_NEW,
              loadComponent: () =>
                import('./components/user/new/new.user.component').then(
                  (m) => m.NewUserComponent
                ),
              data: {
                roles: [
                  Role.PROJECT_MANAGER,
                  Role.CONSULTANT,
                  Role.ADMIN,
                  Role.AUDITOR,
                ],
                hideBreadcrumb: true,
              },
            },
            { path: 'help', component: HelpComponent },
            {
              path: 'contact',
              loadComponent: () =>
                import('./components/static/contact/contact.component').then(
                  (m) => m.ContactComponent
                ),
              data: { hideBreadcrumb: true },
            },
          ],
        },
        {
          path: 'pdd',
          canActivateChild: [AuthGuard],
          loadChildren: () =>
            import('./components/pdd/pdd.module').then((m) => m.PddModule),
        },
        {
          path: 'unauthorized',
          loadComponent: () =>
            import(
              './components/static/unauthorized/unauthorized.component'
            ).then((m) => m.UnauthorizedComponent),
        },
      ],
      {
        anchorScrolling: 'enabled',
        preloadingStrategy: AfterLoginPreloadingStrategy,
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
