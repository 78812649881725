/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, throwError, timeout, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SetTimeoutAction } from '../actions';
import { AppState } from '../states/app.state';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  @Select(AppState.getIsTimeout) isTimeout$: Observable<boolean>;
  isTimeout = false;
  defaultTimeout = 60000;

  constructor(private http: HttpClient, @Inject(Store) private store: Store) {
    this.isTimeout$.subscribe({
      next: (value) => {
        this.isTimeout = value;
      },
    });
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const skipTimeoutValue =
      req.headers.has('skipTimeout') || environment.skipTimeoutCheck;

    if (skipTimeoutValue) {
      return next.handle(req);
    }

    let timeoutValue = this.defaultTimeout;
    if (req.headers.get('timeout')) {
      const headerTimeout = Number.parseInt(req.headers.get('timeout') ?? '');
      if (headerTimeout > timeoutValue) {
        timeoutValue = headerTimeout;
      }
    }

    return next.handle(req).pipe(
      timeout(timeoutValue),
      catchError((err) => {
        if (
          err instanceof TimeoutError &&
          (!this.isTimeout || req.url.includes('ping'))
        ) {
          this.store.dispatch(new SetTimeoutAction(true));
          if (req.url.includes('ping')) {
            throw new Error('Server Timeout');
          } else {
            this.http.get(environment.apiUrl + '/maintenance/ping').subscribe();
          }
        }
        return throwError(() => err);
      })
    );
  }
}
