/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/**
 * Main Layout
 *
 * handles click events for menus
 * updates breadcrumb menu
 *
 */
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  CertificationStatus,
  CertificationStatusLocalization,
} from '@eva/certification/api';
import {
  Certification,
  Document,
  DocumentCategory,
  Project,
  Role,
  SystemMessage,
  User,
} from '@eva/data-access/shared';
import { DialogService } from '@eva/ng-base';
import { Select, Store } from '@ngxs/store';

import { combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, sampleTime, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  LoadAuthenticatedUserAction,
  LogoutAction,
  RefreshTimerAction,
  UpdateUserAction,
} from '../../actions';
import { EnvironmentService } from '../../services/environment.service';
import { MessagingService } from '../../services/messaging.service';
import { AppState } from '../../states/app.state';
import {
  EditOrganisationComponent,
  EditOrganisationDialogData,
} from '../organisation/edit/edit.organisation.component';
import { AgbComponent } from '../user/agb/agb.component';
import { EditUserComponent } from '../user/edit/edit.user.component';
import { MenuService } from './services/menu.service';

@Component({
  selector: 'eva-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @Select(AppState.errors) errors$: Observable<string[]>;

  @Select(AppState.authenticatedUser) authenticatedUserStore$: Observable<User>;

  @Select(AppState.systemMessages) systemMessages$: Observable<SystemMessage[]>;

  @Select(AppState.systemDocuments) systemDocuments$: Observable<Document[]>;

  @Select(AppState.currentProject) currentProject$: Observable<Project>;

  @Select(AppState)
  certificationState$: Observable<AppState>;

  showProfileModal = false;

  topbarMenuActive: boolean;

  menuActive: boolean;

  staticMenuDesktopInactive: boolean;

  mobileMenuActive: boolean;

  menuClick: boolean;

  mobileTopbarActive: boolean;

  topbarRightClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: string;

  configActive: boolean;

  configClick: boolean;

  rightMenuActive: boolean;

  menuHoverActive = false;

  searchClick = false;

  search = false;

  inlineMenuActive: string[] = [];

  inlineMenuClick: boolean;

  userName = 'anonymous';

  organisationName = 'n.a.';

  isOrganisationOwner = false;

  role: Role;

  user: User;

  breadCrumbMenuItems: { label: string; routerLink: string }[] = [];

  hideBreadcrumb = false;

  space = true;

  currentProjectName = '';

  currentCertification: Certification;

  noCertifications = true;

  home: { routerLink: string; label: string } | null = null;

  oldLayout = true;

  buildInfo: {
    build: string;
    environment?: string;
    version: string;
    date: Date;
  };

  private agbChecked = false;

  private _subscriptions: Subscription;

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  // private maintenanceMessageCookie = 'eva-mm';

  constructor(
    public renderer: Renderer2,
    private menuService: MenuService,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public messageService: MessagingService,
    private dialogService: DialogService,
    private environmentService: EnvironmentService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(
      combineLatest({
        user: this.authenticatedUserStore$.pipe(
          tap((user) => {
            this.user = user;
            this.role = Role[user?.role];
            this.userName = user?.firstName + ' ' + user?.lastName;
            this.organisationName = user?.organisation?.name || '';
            this.isOrganisationOwner = user?.organisation?.ownerId === user.id;
            if (this.role === Role.AUDITOR) {
              this.menuActive = false;
            }
          })
        ),
        systemDocuments: this.systemDocuments$,
        systemMessages: this.systemMessages$,
      }).subscribe((data) => {
        // validation for user
        const userAcceptedLatestAgb = (
          user: User,
          latestAgbVersion: string
        ): boolean =>
          user.agbAccepted && user.agbVersionAccepted === latestAgbVersion;
        let latestAgbVersion = '';
        if (data.systemDocuments && data.systemDocuments.length > 0) {
          const agbs = data.systemDocuments.find(
            (d) => d.category === DocumentCategory.AGB_STANDARD
          );
          if (agbs) {
            latestAgbVersion = agbs.id;
          }
          if (data.user) {
            const user = data.user;
            // check this once only
            if (!this.agbChecked) {
              if (
                user &&
                user.role !== Role.ADMIN &&
                !userAcceptedLatestAgb(user, latestAgbVersion)
              ) {
                this.openAgbPopup(user);
              }
              this.agbChecked = true;
            }
            if (
              userAcceptedLatestAgb(user, latestAgbVersion) &&
              data.user.role !== Role.ADMIN
            ) {
              data.systemMessages.forEach((message) => {
                if (user.readMessages?.length) {
                  const r = user.readMessages.find((m) => m.id === message.id);
                  if (r) {
                    return;
                  }
                }
                // don't show system messages & agb popup at the same time
                this.messageService.confirmOnly(
                  message.title,
                  message.text,
                  () => {
                    this.store.dispatch(
                      new UpdateUserAction({
                        id: this.user.id,
                        readMessages: [{ id: message.id }],
                      })
                    );
                  },
                  message.severity,
                  { minWidth: '36rem', maxWidth: '46rem' }
                );
              });
            }
          }
        }
      })
    );
    this._subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.hideBreadcrumb =
            !!this.activatedRoute.firstChild?.snapshot.data['hideBreadcrumb'];
          this.space = !!(
            this.activatedRoute.firstChild?.snapshot.data['space'] ?? true
          );
          this.oldLayout = !!(
            this.activatedRoute.firstChild?.snapshot.data['oldLayout'] ?? true
          );
          this.breadCrumbMenuItems = this.createBreadcrumbs(
            this.activatedRoute.root
          );

          if (this.breadCrumbMenuItems.length > 0) {
            this.home = { routerLink: '/', label: 'Dashboard' };
          } else {
            this.home = null;
          }
        })
    );
    this._subscriptions.add(
      this.errors$.subscribe((errors) => {
        errors.map((error) => {
          this.messageService.error(
            error,
            '(Fehlernummer ' +
              new Date().getTime().toString().substring(3, 7) +
              ' )'
          );
        });
      })
    );
  }

  async ngOnInit() {
    this.menuActive = this.isStatic() && !this.isMobile();

    this._subscriptions.add(
      this.currentProject$.subscribe((p) => {
        this.currentProjectName = p?.name;
        if (
          this.breadCrumbMenuItems.length > 0 &&
          this.breadCrumbMenuItems[0]?.label.indexOf('currentProject') === 0
        ) {
          this.updateBreadCrumb();
        }
      })
    );
    this._subscriptions.add(
      this.certificationState$.subscribe((c) => {
        this.noCertifications = c.certifications.length === 0;
        if (
          this.currentCertification?.id &&
          this.currentCertification?.id === c.currentCertification?.id
        ) {
          if (
            this.currentCertification.status !== c.currentCertification.status
          ) {
            if (c.currentCertification.group) {
              if (
                c.currentCertification.group.status !==
                this.currentCertification.group?.status
              ) {
                this.onStatusChanged(
                  c.currentCertification.group.status,
                  true,
                  true
                );
              } else {
                this.onStatusChanged(
                  c.currentCertification.group.status,
                  true,
                  false
                );
              }
            } else {
              this.onStatusChanged(c.currentCertification.status, false, false);
            }
          }
        }
        this.currentCertification = c.currentCertification;
      })
    );
    const observable = fromEvent(document, 'click');
    // make sure any action extends the timer
    this._subscriptions.add(
      observable
        .pipe(
          sampleTime(5000)
          // tap((f) => console.log('rta'))
        )
        .subscribe(() => {
          this.store.dispatch(new RefreshTimerAction());
        })
    );
    this.environmentService.getBuildInfo().subscribe((buildInfo) => {
      console.log('buildInfo', buildInfo);
      if (buildInfo) {
        this.buildInfo = {
          ...buildInfo,
          date: new Date(buildInfo['date']),
          build: buildInfo['build'].substring(0, 9),
          version:
            environment?.name === 'prod'
              ? buildInfo['version']
              : buildInfo['version'] + '-' + environment?.name,
        };
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  documentClicked() {
    // hides the horizontal submenus or top menu if outside is clicked
    if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
      this.menuService.reset();
    }

    if (!this.menuClick) {
      if (this.mobileMenuActive) {
        this.mobileMenuActive = false;
      }

      if (this.isOverlay()) {
        this.menuActive = false;
      }

      this.menuHoverActive = false;
      this.unblockBodyScroll();
    }

    this.inlineMenuClick = false;
    this.menuClick = false;
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  onMenuClick() {
    this.menuClick = true;
  }

  setMenuBar(show: boolean) {
    this.onMenuButtonClick(null, !show);
  }

  closeMenu() {
    this.menuActive = true;
    this.mobileMenuActive = false;
    this.staticMenuDesktopInactive = true;
  }

  onMenuButtonClick(event: MouseEvent | null, close?: boolean) {
    this.menuActive = close !== undefined ? !close : !this.menuActive;
    this.topbarMenuActive = false;
    this.topbarRightClick = true;
    this.menuClick = true;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.mobileMenuActive = !this.mobileMenuActive;
      if (this.mobileMenuActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    // event?.preventDefault();
  }

  openProfile() {
    this.store.dispatch(new LoadAuthenticatedUserAction());
    this.dialogService.openDialogWithComponent(EditUserComponent, {
      header: 'Benutzerprofil',
      data: {
        editAuthenticatedUser: true,
      },
    });
  }

  openOrganisation() {
    this.dialogService.openDialogWithComponent<EditOrganisationDialogData>(
      EditOrganisationComponent,
      {
        header: 'Organisation',
        modal: true,
        width: '40rem',
        height: '40rem',
        data: {
          organisation: this.authenticatedUserStore$.pipe(
            map((u) => u.organisation)
          ),
        },
      }
    );
  }

  openAgbPopup(user: User) {
    this.dialogService.openDialogWithComponent(AgbComponent, {
      header: 'AGB und Nutzungsbedingungen',
      closable: false,
      modal: true,
      data: {
        user,
      },
    });
  }

  onTopbarMobileButtonClick(event: MouseEvent) {
    this.mobileTopbarActive = !this.mobileTopbarActive;
    event.preventDefault();
  }

  onTopbarItemClick(event: MouseEvent | null, item: string) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = '';
    } else {
      this.activeTopbarItem = item;
    }

    if (item === 'logout') {
      this.store.dispatch(new LogoutAction());
    }
    // event?.preventDefault();
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - ((this.swipeCoord && this.swipeCoord[0]) || 0),
        coord[1] - ((this.swipeCoord && this.swipeCoord[1]) || 0),
      ];
      const duration = time - (this.swipeTime || 0);

      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        const swipe = direction[0] < 0 ? 'left' : 'right';
        if (swipe === 'left') {
          this.onMenuButtonClick(null, true);
        }
      }
    }
  }

  isOverlay() {
    return false;
  }

  isStatic() {
    return true;
  }

  isHorizontal() {
    return false;
  }

  isSlim() {
    return false;
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  private onStatusChanged(
    newStatus: CertificationStatus,
    hasGroupCertification: boolean,
    groupCertificationChanged: boolean
  ) {
    const name = hasGroupCertification
      ? 'Gruppenzertifizierung'
      : 'Zertifizierung';
    let message = `Der Status ist nun "${CertificationStatusLocalization[newStatus]}"`;

    if (
      [
        CertificationStatus.VALID_FOR_REVIEW,
        CertificationStatus.VALID_FOR_RE_REVIEW,
      ].includes(newStatus)
    ) {
      const repeated =
        newStatus === CertificationStatus.VALID_FOR_RE_REVIEW ? 'erneute' : '';
      const location = hasGroupCertification
        ? 'auf dem Dashboard'
        : 'bei den Zertifizierungsdaten';
      if (
        !hasGroupCertification ||
        (hasGroupCertification && groupCertificationChanged)
      ) {
        message += `Sie können nun ${location} die ${repeated} Prüfung beantragen!`;
      }
    }
    this.messageService.success(
      `Der Status der ${name} hat sich geändert!`,
      message,
      true
    );
  }

  /**
   * called initially by route event handle
   * loops recursive through all activatedRoute children
   *
   */
  private createBreadcrumbs(
    route: ActivatedRoute,
    routerLink = '',
    breadcrumbs: { label: string; routerLink: string }[] = []
  ): { routerLink: string; label: string }[] {
    const children: ActivatedRoute[] = route.children;
    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        routerLink += `/${routeURL}`;
      }

      let label = child.snapshot.data['breadcrumb'];

      if (label) {
        if (label === 'currentProject' && this.currentProjectName) {
          label = 'Projekt "' + this.currentProjectName + '"';
          routerLink += '/edit';
        }
        if (
          label === 'currentCertification' &&
          this.currentCertification?.name
        ) {
          label = 'Zertifizierung "' + this.currentCertification.name + '"';
          routerLink += '/edit';
        }
        breadcrumbs.push({ label, routerLink });
      }

      return this.createBreadcrumbs(child, routerLink, breadcrumbs);
    }
    return breadcrumbs;
  }

  /**
   * replace token in breadcrumb with dynamic label
   *
   * current project and current certification exist at the same
   */
  private updateBreadCrumb() {
    if (this.currentProjectName) {
      this.breadCrumbMenuItems = this.breadCrumbMenuItems.map((i) => {
        if (i.label === 'currentProject') {
          i.label = 'Projekt "' + this.currentProjectName + '"';
          i.routerLink += '/edit';
        } else if (i.label.indexOf('Projekt "') === 0) {
          i.label = 'Projekt "' + this.currentProjectName + '"';
        }
        return i;
      });
    }
  }
}
