/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import { Location } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import '@angular/localize/init';
import {
  CertificationPartial,
  Organisation,
  OrganisationFragment,
  Role,
  TextTemplate,
  UpdateOrganisationInput,
} from '@eva/data-access/shared';
import { DialogData } from '@eva/ng-base';
import { Store } from '@ngxs/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Editor } from 'primeng/editor';
import { OverlayPanel } from 'primeng/overlaypanel';
import Quill from 'quill';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  map,
  of,
  switchMap,
} from 'rxjs';
import { UpdateOrganisationAction } from '../../../actions';
import { MessagingService } from '../../../services/messaging.service';
import { TextTemplateService } from '../../../services/text-template.service';
import { AppState } from '../../../states/app.state';

export type OrganisationWithTextTemplates = Omit<
  Organisation,
  'textTemplates'
> & {
  textTemplates: TextTemplate[];
};

export interface EditOrganisationDialogData {
  organisation:
    | BehaviorSubject<Organisation>
    | Observable<OrganisationFragment | null | undefined>;
  consultancies?: OrganisationFragment[];
}

@Component({
  selector: 'eva-edit-organisation',
  templateUrl: './edit.organisation.component.html',
  styleUrls: ['./edit.organisation.component.scss'],
})
export class EditOrganisationComponent implements OnDestroy, OnChanges {
  @Input() organisation: OrganisationWithTextTemplates | undefined;

  currentRole: Role | undefined;
  // for variable-preview only
  sampleCertification$: BehaviorSubject<
    CertificationPartial | null | undefined
  > = new BehaviorSubject<CertificationPartial | null | undefined>(null);

  organisationForm;

  organisationUpdateSubscription: Subscription | undefined;

  organisationId: string | null;

  roleEnum = Role;

  consultancyOptions: OrganisationFragment[] = [];

  private _subscription: Subscription = new Subscription();

  createForm() {
    return this.formBuilder.group({
      general: this.nnFormBuilder.group({
        name: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, Validators.minLength(2)],
        }),
        description: new FormControl(''),
        consultancy: new FormControl<string | null>(null),
      }),
      address: this.formBuilder.group({
        zip: ['', { validators: Validators.required }],
        city: ['', { validators: Validators.required }],
        address: ['', { validators: Validators.required }],
      }),

      contact: this.formBuilder.group({
        website: '',
        contact: ['', { validators: Validators.required }],
        contactMail: ['', { validators: Validators.required }],
        contactPhone: '',
        internalContact: '',
      }),
      textTemplates: this.formBuilder.array([this.getTextTemplateFromGroup()]),
    });
  }

  getTextTemplateFromGroup(textTemplate?: TextTemplate) {
    return this.formBuilder.group({
      id: new FormControl(textTemplate?.id ?? '', {
        nonNullable: false,
      }),
      name: new FormControl(textTemplate?.name ?? '', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(2)],
      }),
      text: new FormControl(textTemplate?.text ?? '', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(2)],
      }),
    });
  }

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private nnFormBuilder: NonNullableFormBuilder,
    private messagingService: MessagingService,
    public location: Location,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<DialogData<EditOrganisationDialogData>>,
    public textTemplateService: TextTemplateService
  ) {
    this.currentRole = this.store.selectSnapshot<Role>(
      (state: any) => state.app?.authenticatedUser?.role
    );
    this.organisationForm = this.createForm();

    if (this.config.data) {
      this.config.data.actions = [
        {
          text: 'Speichern',
          i18nLabel: '@@save',
          icon: {
            name: 'pi pi-check',
          },
          disable: () => {
            return this.organisationForm;
          },
          action: () => {
            return this.submitForm();
          },
        },
      ];
    }
    //Load sample certification for variable-preview
    this.store
      .select(AppState.currentCertification)
      .pipe(
        switchMap((c) => {
          if (c) {
            return of(c);
          } else {
            return this.store.select(AppState.projects).pipe(
              map((p) => {
                if (p?.length > 0) {
                  const myProject = p[p.length - 1];
                  if (
                    myProject?.certifications &&
                    myProject?.certifications.length > 0
                  ) {
                    return {
                      ...myProject.certifications[0],
                      project: myProject,
                    } as CertificationPartial;
                    // return myProject.certifications[0];
                  }
                }
                return null;
              })
            );
          }
        })
      )
      .subscribe(this.sampleCertification$);

    if (config.data) {
      this.organisationForm.reset();
      if (this.organisationUpdateSubscription) {
        this.organisationUpdateSubscription.unsubscribe();
      }
      this.organisationUpdateSubscription = config.data.organisation.subscribe(
        (o) => {
          this.organisation = o as OrganisationWithTextTemplates;
          this.organisationChanged();
        }
      );
      if (this.currentRole === Role.ADMIN) {
        this.consultancyOptions = config.data.consultancies ?? [];
      }
    }
  }

  patchForm(organisation: OrganisationWithTextTemplates) {
    this.organisationForm.patchValue({
      general: {
        name: organisation.name,
        description: organisation.description,
        consultancy: organisation.consultancyId ?? null,
      },
      address: {
        zip: organisation.zip,
        city: organisation.city,
        address: organisation.address,
      },
      contact: {
        website: organisation.website,
        contact: organisation.contact,
        contactMail: organisation.contactMail,
        contactPhone: organisation.contactPhone,
        internalContact: organisation.internalContact,
      },
    });

    this.organisationForm.setControl(
      'textTemplates',
      this.formBuilder.array(
        organisation.textTemplates.map((tt) =>
          this.getTextTemplateFromGroup(tt)
        )
      )
    );

    this.organisationForm.controls.textTemplates.setValue(
      organisation.textTemplates
    );
  }

  getFormData(): Partial<UpdateOrganisationInput> {
    return {
      address: this.organisationForm.controls.address.controls.address.value,
      zip: this.organisationForm.controls.address.controls.zip.value,
      city: this.organisationForm.controls.address.controls.city.value,

      name: this.organisationForm.controls.general.controls.name.value,
      description:
        this.organisationForm.controls.general.controls.description.value,
      contact: this.organisationForm.controls.contact.controls.contact.value,
      contactMail:
        this.organisationForm.controls.contact.controls.contactMail.value,
      contactPhone:
        this.organisationForm.controls.contact.controls.contactPhone.value,
      internalContact:
        this.organisationForm.controls.contact.controls.internalContact.value,
      website: this.organisationForm.controls.contact.controls.website.value,
      textTemplates: this.organisationForm.controls.textTemplates.controls.map(
        (tt) => {
          return {
            id: tt.controls.id.value ? tt.controls.id.value : undefined,
            name: tt.controls.name.value,
            text: tt.controls.text.value,
          };
        }
      ),
      consultancyId:
        this.organisationForm.controls.general.controls.consultancy.value,
    };
  }

  organisationChanged() {
    if (this.organisation) {
      this.organisationForm.reset();
      this.patchForm(this.organisation);
      // this.insertTextTemplates()
      this.organisationId = this.organisation?.id ?? null;
    } else {
      this.organisationForm.reset();
      this.organisationId = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisation) {
      this.organisationChanged();
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  cancel() {
    this.organisationForm.reset();
    this.ref.close();
  }

  insertTextTemplates() {
    this.organisation?.textTemplates.forEach((tt) => {
      this.addTextTemplate(tt);
    });
  }

  get textTemplatesFormArray() {
    return this.organisationForm.controls.textTemplates as FormArray;
  }

  submitForm(): Subscription {
    if (this.organisation?.id) {
      return this.store
        .dispatch(
          new UpdateOrganisationAction({
            id: this.organisation?.id,
            name: '',
            ...this.getFormData(),
          })
        )
        .subscribe((state: { app: AppState }) => {
          this.messagingService.success(
            '',
            $localize`:@@organisation-updated:Die Organisation wurde aktualisiert`
          );
          this.organisationUpdateSubscription?.unsubscribe();
          this.ref.close();
        });
    }

    return Subscription.EMPTY;
  }

  newTextTemplateClicked() {
    this.addTextTemplate();
  }

  deleteTextTemplate(textTemplateForm: FormGroup) {
    this.textTemplatesFormArray.removeAt(
      this.textTemplatesFormArray.controls.indexOf(textTemplateForm)
    );
    this.textTemplatesFormArray.markAsDirty();
  }

  trackByFn(index: number) {
    return index;
  }

  addTextTemplate(textTemplate?: TextTemplate) {
    if (!textTemplate) {
      textTemplate = {} as TextTemplate;
    }

    this.organisationForm.controls.textTemplates.push(
      this.formBuilder.group({
        id: new FormControl(textTemplate.id, {
          nonNullable: false,
        }),
        name: new FormControl(textTemplate.name, {
          nonNullable: true,
          validators: [Validators.required, Validators.minLength(2)],
        }),
        text: new FormControl(textTemplate.text, {
          nonNullable: true,
          validators: [Validators.required, Validators.minLength(2)],
        }),
      })
    );
    this.organisationForm.updateValueAndValidity();
  }

  insertVariable(
    overlay: OverlayPanel,
    conclusionEditor: Editor,
    variable: string
  ) {
    overlay.hide();
    const selection = (conclusionEditor.getQuill() as Quill).getSelection(true);
    // variablesOverlay?.hide();
    (conclusionEditor.getQuill() as Quill).insertText(
      selection.index,
      variable,
      'user'
    );
    (conclusionEditor.getQuill() as Quill).setSelection(
      selection.index + variable.length,
      0
    );
  }
}
